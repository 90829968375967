import React, { useMemo } from 'react';
import styled from 'styled-components';
const LogoComponent: React.FC<{ varient: 'dark' | 'transparent'; customWidth?: string }> = ({
    varient,
    customWidth,
}) => {
    const src = useMemo(() => {
        if (varient === 'dark') {
            return '/assets/images/textroot-logo.jpg';
        } else if (varient === 'transparent') {
            return '/assets/images/texroot-logo-transparent-2.png';
        }
        return '';
    }, [varient]);
    return <LogoImage src={src} className={'logo my-2'} varient={varient} customWidth={customWidth} />;
};
export default LogoComponent;
const LogoImage = styled.img<{ varient: string; customWidth?: string }>`
    width: ${({ varient }) => (varient === 'transparent' ? '10rem' : '10rem')};
    ${({ customWidth }) => (customWidth ? `width:${customWidth}` : '')};
`;
