import { createReducer } from 'redux-act';
import { setEnvironment, signOutUser, hideLoader, signInUser, setCompliance, setBrowserId } from '../actions';
import { ENV_TYPE } from '../utils/apiConstants';

export interface IUserSettingsReducer {
    environment: string;
    isUserCompliance: boolean;
    isLoading: boolean;
    browserId: string;
}

const initialState = {
    environment: ENV_TYPE.PROD,
    isUserCompliance: false,
    isLoading: false,
    browserId: '',
};

export const userSettings = createReducer<IUserSettingsReducer>({}, initialState);

userSettings.on(setEnvironment, (state: IUserSettingsReducer, payload: string) => ({
    ...state,
    environment: payload,
}));

userSettings.on(hideLoader, (state: IUserSettingsReducer) => ({
    ...state,
    isLoading: false,
}));

userSettings.on(setCompliance, (state: IUserSettingsReducer, payload: boolean) => ({
    ...state,
    isUserCompliance: payload,
}));

userSettings.on(signInUser, (state: IUserSettingsReducer) => ({
    ...state,
    isLoading: true,
}));
userSettings.on(setBrowserId, (state: IUserSettingsReducer, payload: string) => ({
    ...state,
    browserId: payload,
}));
userSettings.on(signOutUser, (state: IUserSettingsReducer) => ({ ...initialState, browserId: state.browserId }));
