import React, { useCallback, useRef, useState } from 'react';
import useOutsideAlerter from '../../modules/hooks/useOutsideAlerter';
import classnames from 'classnames';

interface IDropdown {
    mainContent: JSX.Element;
    mainContentWrapperClass?: string;
    wrapperElementType: string;
    children: JSX.Element | JSX.Element[];
    dropdownContentWrapperClass?: string;
}
const Dropdown: React.FC<IDropdown> = ({
    wrapperElementType,
    children,
    dropdownContentWrapperClass,
    mainContent,
    mainContentWrapperClass,
}) => {
    const [show, setShow] = useState(false);
    const ref = useRef<any>(null);
    const showDropDown = useCallback(() => {
        setShow(true);
    }, []);
    const hideDropDown = useCallback(() => {
        setShow(false);
    }, []);

    useOutsideAlerter(ref, () => {
        hideDropDown();
    });

    if (wrapperElementType === 'li') {
        return (
            <li ref={ref}>
                <a
                    className={`dropdown-toggle ${mainContentWrapperClass}`}
                    href="javascript:void(0);"
                    onClick={showDropDown}
                >
                    {mainContent}
                </a>
                <ul className={`dropdown-menu ${dropdownContentWrapperClass} ${classnames({ show })}`}>{children}</ul>
            </li>
        );
    }
    return (
        <div ref={ref}>
            <a
                className={`dropdown-toggle ${mainContentWrapperClass}`}
                href="javascript:void(0);"
                onClick={showDropDown}
            >
                {mainContent}
            </a>
            <ul className={`dropdown-menu ${dropdownContentWrapperClass} ${classnames({ show })}`}>{children}</ul>
        </div>
    );
};
export default Dropdown;
