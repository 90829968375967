import Swal from 'sweetalert2';

export const showAlert = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            const { title, text, icon, confirmButtonText, denyButtonText } = data;
            Swal.fire({
                title,
                text,
                icon,
                confirmButtonText,
                showDenyButton: !!denyButtonText,
                denyButtonText,
            })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(() => {
                    reject(0);
                })
                .finally(() => {});
        } catch (e) {
            reject(0);
            console.log(e);
        }
    });
};
