import { createAction } from "redux-act";
import { IUser, IUsersAdhocCharge, IUsersBill } from "../reducers";

export const fetchUsers = createAction<{
  filterBy?: string | null;
  searchText?: string;
}>("get all address");

export const setTotalUsersRecords = createAction<number>(
  "set total users records "
);
export const setTotalAddressRecords = createAction<number>(
  "set total address records "
);
export const setTotalSubUserRecords = createAction<number>(
  "set total sub user records "
);
export const setUsers = createAction<IUser[]>("set users");
export const toggleUserLoader = createAction<boolean>("toggle user loader");
export const changeUsersCurrentPage = createAction<number>(
  "set users current page"
);
export const changeUsersPerPage = createAction<number>("set users per page");
export const createLoginUrl = createAction<{ id: number }>("create login url");
export const fetchUserDetails = createAction<{ id: number }>(
  "fetch user details"
);
export const fetchUserBills = createAction<{ id: number }>("fetch user bills");
export const changeUserPassword = createAction<{
  password: string;
  id: number;
}>("change user password");
export const setSelectedUser = createAction<IUser | undefined>(
  "set selected user"
);
export const setSelectedUserBill =
  createAction<IUsersBill[]>("set selected user");

export const togglePlanchooserModal = createAction<boolean>(
  "toggle plan chooser modal"
);
export const toggleChangeExpiryDateModal = createAction<boolean>(
  "toggle change expiry date modal"
);
export const changeExpiryDate = createAction<{
  id: number;
  expiryDate: string;
}>("change expiry date");

export const setAllPlans = createAction<any>("set all plans");
export const setPrefetchedCardData = createAction<any>(
  "set prefetched card data"
);
export const setLastInvoice = createAction<any>("set last invoice");
export const updatePlan = createAction<{ plan_id: number; id: number }>(
  "upgrade my plan"
);
export const setCredits = createAction<number>("set my credits");
export const getCredits = createAction<{ id: number }>("get my credits");
export const getAllPlans = createAction<{ id: number }>("get all plans");
export const getLastInvoiceAction = createAction<{ id: number }>(
  "get last invoice"
);
export const getCardDetails = createAction<{ id: number }>("get card details");
export const resetUsersDetails = createAction("reset all details");
export const resetUsersPassword = createAction<{email: string}>("reset User password");
export const updateHostedNumberData = createAction<any>(
  "update hosted number data"
);
export const getHostedNumberData = createAction<{ id: number }>(
  "get hosted number data"
);
export const changeHostedNumberData = createAction<{
  key: string;
  value: string;
}>("change hosted number data");
export const checkAndVerifyHostedNumber = createAction<{
  id: number;
}>("change hosted number data");
export const changeUserStatus = createAction<{ id: number; status: string }>(
  "change user status data"
);
export const suspendUserAccount = createAction<{ id: number; }>(
  "suspend user account"
);

export const fetchAdhocCharges = createAction<{ id: number }>(
  "fetch adhoc charges"
);
export const setAdhocCharges =
  createAction<IUsersAdhocCharge[]>("set adhoc charges");
export const createAdhocCharges = createAction<IUsersAdhocCharge>(
  "create adhoc charges"
);
export const updateAdhocChargeData = createAction<{
  key: string;
  value: string | number;
}>("update adhoc charges data");

export const setSingleAdhocChargeData = createAction<any>(
  "set adhoc charges data"
);

export const toggleAdhocChargeModal = createAction<boolean>(
  "toggle adhoc charges"
);

export const fetchAllBills = createAction<{ limit: number, offset: number }>(
  "fetch bills"
);

export const setAllUserBills = createAction<IUsersBill[]>(
  "set all user bills"
);
export const setAllUserBillsCount = createAction<number>(
  "set all user bills count"
);

export const setAllUserBillsPerPage = createAction<number>(
  "set all user bills per page"
);
export const setAllUserBillsCurrentPage = createAction<number>(
  "set all user bills current page"
);

export const runCycleForAllUsers = createAction<{ month: number; year: number; users: number[] }>(
  "run billing cycle"
);

export const finalizePayment = createAction<number[]>(
  "finalizePayment for selected user"
);
