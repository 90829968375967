import React, { useCallback } from 'react';
import styled from 'styled-components';
import { signOutUser, toggleSideMenu } from './modules/actions';
import { useDispatch, useSelector } from 'react-redux';
import LogoComponent from './components/Logo';
import { Link } from 'react-router-dom';
import Avatar from './components/Avatar';
import Dropdown from './components/Common/Dropdown';
import { getCurrentUser } from './modules/selectors';
const NavbarMain = styled.nav`
    max-width: 100% !important;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px !important;
`;
function HeaderLayout() {
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);
    const toggleSideMenuCallback = useCallback(() => {
        dispatch(toggleSideMenu());
    }, [dispatch]);
    const signout = useCallback(() => {
        dispatch(signOutUser());
    }, [dispatch]);
    return (
        <NavbarMain
            className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
            id="layout-navbar"
        >
            <div className="container-xxl">
                <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
                    <Link to="/" className="app-brand-link gap-2">
                        <span className="app-brand-logo demo">
                            <LogoComponent varient={'transparent'} />
                        </span>
                    </Link>

                    <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                        <i className="ti ti-x ti-sm align-middle" />
                    </a>
                </div>

                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                    <a
                        className="nav-item nav-link px-0 me-xl-4"
                        href="javascript:void(0)"
                        onClick={toggleSideMenuCallback}
                    >
                        <i className="ti ti-menu-2 ti-sm" />
                    </a>
                </div>

                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <Dropdown
                            wrapperElementType={'li'}
                            dropdownContentWrapperClass={'dropdown-menu-end'}
                            mainContent={
                                <div className="avatar">
                                    <Avatar name={`${currentUser?.first_name} ${currentUser?.last_name}`} />
                                </div>
                            }
                            mainContentWrapperClass={'nav-link dropdown-toggle hide-arrow'}
                        >
                            <li>
                                <Link to="/" className={'dropdown-item'} onClick={signout}>
                                    <i className="ti ti-logout me-2 ti-sm" />
                                    <span className="align-middle">Log Out</span>
                                </Link>
                            </li>
                        </Dropdown>
                    </ul>
                </div>

                <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
                    <input
                        type="text"
                        className="form-control search-input border-0"
                        placeholder="Search..."
                        aria-label="Search..."
                    />
                    <i className="ti ti-x ti-sm search-toggler cursor-pointer" />
                </div>
            </div>
        </NavbarMain>
    );
}
export default HeaderLayout;
