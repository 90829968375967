import React from 'react';

interface ITRAvatar {
    name: string;
    color?: string;
}
const Avatar: React.FC<ITRAvatar> = ({ color = 'primary', name }) => {
    return (
        <span className={`avatar-initial rounded-circle bg-label-${color}`}>
            {name
                .split(' ')
                .map((x) => x.charAt(0).toUpperCase())
                .join('')
                .slice(0, 2)}
        </span>
    );
};

export default Avatar;
