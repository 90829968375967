import { signOutUser } from '../actions';
import Store from '../store';
import Axios from 'axios';
import { ENV } from './apiConstants';
import { Store as StoreType } from 'redux';
import Swal from "sweetalert2";

const interceptor = {
    setupInterceptors: (store: StoreType) => {
        // Requests interceptor
        Axios.interceptors.request.use(
            (config: any) => {
                const headers = { ...config.headers };
                const state = store.getState();
                const jwt = state.user?.user?.token || '';
                const baseUrl = ENV[state.userSettings.environment];
                headers['Content-Type'] = 'application/json';
                if (config.headers['Content-Type']) {
                    headers['Content-Type'] = config.headers['Content-Type'];
                }
                if (!headers.Authorization) {
                    headers.Authorization = `Bearer ${jwt}`;
                }
                config.headers = headers;
                if (!config.baseURL) {
                    config.baseURL = baseUrl;
                }
                return config;
                /**
                 * show loader
                 * save token and attache to headers
                 */
            },
            (error) => {
                return Promise.reject(error ? error.response : null);
            },
        );

        // Response interceptor
        Axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error?.response?.status === 401) {
                    const errorMsg = 'Session expired';
                    // console.log('** 401 **');
                    const state = store.getState();
                    const isAuthorized = state.user.isAuth;
                    if (isAuthorized) {
                        // window.alert('Your session has expired, please login again to continue.');
                        Swal.fire({
                            title: "Session expired!",
                            text: "Your session has expired, please login again to continue.",
                            icon: "error",
                            confirmButtonText: "Okay",
                            showDenyButton: false
                        })
                    }
                    Store.dispatch(signOutUser());
                    return Promise.reject(errorMsg);
                }
                return Promise.reject(error);
            },
        );
    },
};
export default interceptor;
